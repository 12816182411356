import { BlogPostsMainData, CategoryData } from "@/Types/types";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, HStack, Slider } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import CardImage from "@/components/CardImage";

interface Props {
  data: BlogPostsMainData[];
  topics?: Record<"attributes", CategoryData>[];
}

export default function TrendingCarousel({ data, topics }: Props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const numDots = Math.ceil(data.length <= 4 ? 1 : data.length / 4);

  useEffect(() => {
    if (containerRef.current) {
      setScrollPosition(containerRef.current.scrollLeft);
    }
  }, []);

  const handleScroll = (value: number) => {
    if (containerRef.current) {
      const currentIndex = Math.round(value / containerRef.current.clientWidth);
      setCurrentSlide(currentIndex);
      setScrollPosition(value);
      containerRef.current.scrollTo({ left: value, behavior: "smooth" });
    }
  };

  const handleNext = () => {
    if (containerRef.current) {
      const slidesPerPage = Math.min(data.length, 4);
      const currentIndex = Math.floor(
        scrollPosition / (containerRef.current.clientWidth * slidesPerPage)
      );
      const newPosition =
        (currentIndex + 1) * containerRef.current.clientWidth * slidesPerPage;
      setCurrentSlide(currentIndex + 1);
      setScrollPosition(newPosition);
      containerRef.current.scrollTo({ left: newPosition, behavior: "smooth" });
    }

  };

  const handlePrev = () => {
    if (containerRef.current) {
      const clientWidth = containerRef.current?.clientWidth || 0;
      const slidesPerPage = Math.min(data.length, 4);
      const newPosition = Math.max(
        scrollPosition - clientWidth * slidesPerPage,
        0
      );
      const currentIndex = Math.floor(
        newPosition / (clientWidth * slidesPerPage)
      );
      setCurrentSlide(currentIndex);
      setScrollPosition(newPosition);
      containerRef.current.scrollTo({ left: newPosition, behavior: "smooth" });
    }
  };

  const handleSlideChange = (index: number) => {
    const slidesPerPage = Math.min(data.length, 4);
    const newPosition =
      index * (containerRef.current?.clientWidth || 0) * slidesPerPage;
    setCurrentSlide(index);
    setScrollPosition(newPosition);
    containerRef.current?.scrollTo({ left: newPosition, behavior: "smooth" });
  };

  const canGoNext = currentSlide < numDots - 1;
  const canGoPrev = currentSlide > 0;

  return (
    <Flex
      as="section"
      width="100%"
      paddingBottom={{ base: "60px", lg: "80px" }}
      paddingTop={{ base: "60px", lg: "80px" }}
      position="relative"
    >
      <Flex
        direction="column"
        maxWidth="1216px"
        margin="0 auto"
        width="90%"
        mb="32px"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Heading
            as="h2"
            fontSize="36px"
            lineHeight="40px"
            fontWeight="800"
            color="gray.800"
          >
            Trends
          </Heading>
          <HStack spacing="16px" mt={2}>
            {Array.from({ length: numDots }).map((_, index) => (
              <Box
                key={index}
                w={index === currentSlide ? "12px" : "8px"}
                h={index === currentSlide ? "12px" : "8px"}
                borderRadius="full"
                bg={index === currentSlide ? "#ABA4F6" : "gray.300"}
                cursor="pointer"
                onClick={() => handleSlideChange(index)}
              />
            ))}
          </HStack>
        </Flex>
        <Flex w="100%">
          <Box position="relative">
            <Button
              aria-label="previous"
              position="absolute"
              top="50%"
              left="0px"
              transform={"translateX(-50%)"}
              size="md"
              borderRadius="9999px"
              h="40px"
              w="40px"
              bgColor="gray.100"
              color="gray.700"
              box-shadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
              zIndex="3"
              onClick={handlePrev}
              isDisabled={!canGoPrev}
              _disabled={{ bgColor: "gray.50", color: "gray.200", cursor: "not-allowed" }}
            >
              <ArrowBackIcon />
            </Button>
          </Box>
          <Slider
            w={0}
            value={scrollPosition}
            min={0}
            max={
              (containerRef.current?.scrollWidth ?? 0) -
              (containerRef.current?.clientWidth ?? 0) || 0
            }
            step={containerRef.current?.clientWidth || 0}
            onChange={handleScroll}
            aria-labelledby="slider-label"
          />
          <Flex
            direction="row"
            gap="32px"
            h="480px"
            mt="32px"
            overflowX="hidden"
            ref={containerRef}
          >
            {data.map((item) => (
              <CardImage
                key={item.id}
                post={item}
                width="280px"
              />
            ))}
          </Flex>
          <Box position="relative">
            <Button
              aria-label="forward"
              position="absolute"
              top="50%"
              right="-40px"
              transform={"translateX(-50%)"}
              size="md"
              borderRadius="9999px"
              h="40px"
              w="40px"
              bgColor="gray.100"
              color="gray.700"
              box-shadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
              zIndex="3"
              onClick={handleNext}
              isDisabled={!canGoNext}
              _disabled={{ bgColor: "gray.50", color: "gray.200", cursor: "not-allowed" }}
            >
              <ArrowForwardIcon />
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
