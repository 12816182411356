import React, { useEffect, useState } from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { GetServerSideProps } from "next";
import { NextSeo } from "next-seo";
import { Box } from "@chakra-ui/react";
import { handleError } from "@/helpers/handleError";
import { GET_HEADER_DATA, GET_HOMEPAGE_DATA, GET_CATEGORY_POSTS, GET_FOOTER_DATA } from "@/graphql/queries";
import { CategoryData } from "@/Types/types";

import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import MainBanner from "@/components/MainPage/MainBanner";
import RecentPosts from "@/components/MainPage/RecentPosts";
import SubscribeBlock from "@/components/SubscribeBlock";
import BrowseByCategory from "@/components/MainPage/BrowseByCategory";
import TrendingCarousel from "@/components/MainPage/TrendingCarousel";

export default function Home(props: any) {
  const [isMobile, setIsMobile] = useState<Boolean>();
  const categories: Record<"attributes", CategoryData>[] =
    props.categories.data;
  const filteredCategories = categories.filter(
    (item) => item?.attributes?.categoryPosts?.data?.length
  );

  useEffect(() => {
    setIsMobile(window.innerWidth <= 991 ? true : false);
  })

  return (
    <Box bgColor="#FFFCF6">
      <HeaderMenu cookies={props.cookies.data} menu={props.header[0].items} submenu={props.navbar.data?.attributes.submenu_header} />
      <NextSeo
        title={props.homepage.data.attributes.metaTitle}
        description={props.homepage.data.attributes.metaDescription}
      />
      {Boolean(props.homepage.data.attributes.articles.data.length) && (
        <MainBanner data={props.homepage.data.attributes.articles.data} />
      )}
      <BrowseByCategory posts={props.latest.data} />
      <SubscribeBlock />
      <RecentPosts
        data={props.fourLatest.data}
        sponsorImg={
          props.homepage.data.attributes.sponsorImg.data.attributes.url
        }
        sponsorLink={props.homepage.data.attributes.sponsorLink}
        filteredCategories={filteredCategories}
      />
      {!isMobile && Boolean(props.trending.data.length) && (
        <TrendingCarousel
          data={props.trending.data}
          topics={categories}
        />
      )}
      <Footer menu={props.footer[0].items} />
    </Box>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  try {
    const client = new ApolloClient({
      uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
      cache: new InMemoryCache(),
    });

    const { data: headerData } = await client.query({
      query: GET_HEADER_DATA,
    });

    const { data: footerData } = await client.query({
      query: GET_FOOTER_DATA,
    });

    const { data: homepageData } = await client.query({
      query: GET_HOMEPAGE_DATA,
    });

    const { data: categoryArticlesData } = await client.query({
      query: GET_CATEGORY_POSTS,
    });

    return {
      props: {
        ...headerData,
        ...footerData,
        ...homepageData,
        ...categoryArticlesData,
      },
    };
  } catch (error) {
    handleError(error, "Index getServerSideProps");
    return {
      notFound: true,
    };
  }
};
