import {
  Stack,
  Text,
  Avatar,
  Flex,
  Tag,
  Badge,
} from "@chakra-ui/react";
import Link from "next/link";
import { BlogPostsMainData } from "@/Types/types";
import { formatDate } from "../helpers/formatDate";
import { configFile } from "@/config";

interface Props {
  post?: BlogPostsMainData;
  tag?: boolean;
  author?: boolean;
  width?: string;
  isBreed?: boolean;
}

export default function CardImage({ post, tag, author, width, isBreed }: Props) {
  const { url, title, readingTime, updatedAt } = post!.attributes;
  const date = formatDate(updatedAt);

  return (
    <Flex
      padding="16px"
      direction="column"
      justifyContent="space-between"
      alignItems="flex-end"
      width={width ? width : "100%"}
      minWidth={{ base: "100%", lg: width ? width : "190px" }}
      height="100%"
      bg={`linear-gradient(180deg, rgba(45, 55, 72, 0) 0%, #2D3748 88.02%), url(${isBreed ?
        post!.attributes.image.data.attributes.url : post!.attributes.mainImage.data.attributes.url
        })`}
      bgRepeat="no-repeat"
      bgSize="cover"
      bgPosition="center"
      flexDirection={{ base: "column", md: "row" }}
      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
      borderRadius="8px"
      overflow="hidden"
      transform="translateY(rem)"
      transition="transform 250ms ease, box-shadow 250ms ease"
      _hover={{
        textDecoration: "none",
        boxShadow:
          "0px 2px 4px rgb(46 41 51 / 8%), 0px 5px 10px rgb(71 63 79 / 16%)",
        transform: "translateY(-0.25rem)",
      }}
      position="relative"
      as={Link}
      href={isBreed ? `/raca/${url}` : `/blog/${url}`}
    >
      <Flex
        direction="column"
        justifyContent={tag ? "space-between" : author ? "space-between" : "flex-end"}
        alignItems="flex-start"
        gap="24px"
        width={{ base: "100%", md: "auto" }}
        height="100%"
        marginStart={{ base: "0", md: "8px" }}
      >
        {tag && (
          <Flex gap="8px">
            {isBreed ?
              post?.attributes.category?.data.slice(0, 2).map((category: any, i: number) => (
                <Badge
                  key={i}
                  bgColor={
                    category.attributes.bgColor
                  }
                  color={
                    category.attributes.color
                  }
                  textTransform="uppercase"
                >
                  {category.attributes.title}
                </Badge>
              ))
              :
              post?.attributes.category.data.slice(0, 2).map((category: any, i: number) => (
                <Badge
                  key={i}
                  bgColor={category.attributes.bgColor
                  }
                  color={category.attributes.color
                  }
                  textTransform="uppercase"
                >
                  {category.attributes.title}
                </Badge>
              ))
            }

          </Flex>
        )}

        <Text
          lineHeight="1.4"
          fontWeight="bold"
          fontSize="20px"
          color="#FFF"
          alignSelf="stretch"
        >
          {isBreed ? post?.attributes.name : title}
        </Text>

        {author && (
          <Stack
            alignSelf="stretch"
            direction="row"
            justify="flex-start"
            align="center"
            spacing="16px"
          >
            <Avatar
              width="48px"
              height="48px"
              name={post!.attributes.author.data.attributes.name}
              src={
                post!.attributes.author.data.attributes.img?.data.attributes.url
              }
            />
            <Flex direction="column" gap="4px">
              <Text
                fontSize="14px"
                textDecoration="none"
                fontWeight="500"
                color={configFile.secondaryAccentColor}
                p="0 !important"
              >
                {post!.attributes.author.data.attributes.name}
              </Text>
              <Text
                lineHeight="1.33"
                fontWeight="regular"
                fontSize="12px"
                color={configFile.secondaryAccentColor}
                alignSelf="stretch"
              >
                {date} • {readingTime}
                min
              </Text>
            </Flex>
          </Stack>
        )}
      </Flex>
    </Flex>
  );
}
