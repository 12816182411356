import React, { useEffect, useState } from "react";
import { Box, Heading, Flex, Text, Badge, Link as ChakraLink } from "@chakra-ui/react";
import { MainBanner } from "@/Types/types";
import Image from "next/image";

import PostCardNoImg from "@/components/PostCardNoImg";
import Link from "next/link";

interface Props {
  data: MainBanner[];
}

interface CurrentPost {
  title: string;
  description: string;
  url: string;
  categoryTitle: string;
  categoryBgColor: string;
  categoryColor: string;
  categoryUrl: string;
  imageSRC: string;
}

function MainBanner({ data }: Props) {
  const [currentPost, setCurrentPost] = useState<CurrentPost>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    setCurrentPost({
      title: `${data[0].attributes?.title}`,
      description: `${data[0].attributes?.previewText}`,
      url: `/blog/${data[0].attributes?.url}`,
      categoryTitle: `${data[0].attributes?.category.data[0].attributes.title}`,
      categoryBgColor: `${data[0].attributes?.category.data[0].attributes.bgColor}`,
      categoryColor: `${data[0].attributes?.category.data[0].attributes.color}`,
      categoryUrl: `${data[0].attributes?.category.data[0].attributes.slug}`,
      imageSRC: `${data[0].attributes?.mainImage.data.attributes.url}`,
    });
  }, []);

  const handleMouseOn = (item: MainBanner, index: number) => {
    setCurrentIndex(index);
    setCurrentPost({
      title: `${item.attributes?.title}`,
      description: `${item.attributes?.previewText}`,
      url: `/blog/${item.attributes?.url}`,
      categoryTitle: `${item.attributes?.category.data[0].attributes.title}`,
      categoryBgColor: `${item.attributes?.category.data[0].attributes.bgColor}`,
      categoryColor: `${item.attributes?.category.data[0].attributes.color}`,
      categoryUrl: `${item.attributes?.category.data[0].attributes.slug}`,
      imageSRC: `${item.attributes?.mainImage.data.attributes.url}`,
    });
  }

  return (
    <Box
      as="section"
      flexDirection={{ base: "column", lg: "row" }}
      width="100%"
      height={{ base: "auto", md: "604" }}
      paddingBottom={{ base: "0", lg: "80px" }}
      paddingTop={{ base: "0", lg: "80px" }}
    >
      <Flex
        direction="column"
        maxWidth="1216px"
        width="90%"
        margin="0 auto"
        p={{ base: "32px 0 24px 0", lg: "56px 0" }}
        gap={{ base: "16px", lg: "64px" }}
      >

        <Flex gap="32px" width="100%" direction={{ base: "column", lg: "row-reverse" }}>
          <ChakraLink
            as={Link}
            href={`${currentPost?.url}`}
            _hover={{ textDecoration: "none" }}
          >
            <Box
              width={{ base: "100%", lg: "592px" }}
              height={{ base: "236px", lg: "410px" }}
              position="relative"
              borderRadius="8px 32px 32px 32px"
              overflow="hidden"
            >
              <Image
                priority
                fill
                src={currentPost?.imageSRC || "/images/dog.jpg"}
                alt={`${currentPost?.title}`}
                style={{ objectFit: "cover", position: "absolute" }}
              />
            </Box>
          </ChakraLink>
          <Flex
            direction="column"
            justifyContent="space-between"
            maxWidth="592px"
            padding="24px 0"
          >
            <ChakraLink
              as={Link}
              href={`${currentPost?.url}`}
              _hover={{ textDecoration: "none" }}
            >
              <Flex
                display="flex"
                flexDirection="column"
                rowGap="16px"
                width="100%"
                height="100%"
              >
                <Badge
                  variant="solid"
                  bgColor={currentPost?.categoryBgColor}
                  color={currentPost?.categoryColor}
                  textTransform="uppercase"
                  mb={{ base: "0", lg: "8px" }}
                  width="fit-content"
                >
                  {currentPost?.categoryTitle}
                </Badge>
                <Heading
                  as="h1"
                  fontSize={{ base: "30px", lg: "48px" }}
                  lineHeight={{ base: "36px", lg: "48px" }}
                  color="gray.800"
                  width="100%"
                >
                  {currentPost?.title}
                </Heading>
                <Text
                  fontSize="18px"
                  lineHeight="28px"
                  color="gray.600"
                >
                  {currentPost?.description}
                </Text>
              </Flex>
            </ChakraLink>
            <Flex gap="16px" alignItems="center" mt={{ base: "32px", lg: "0" }}>
              {data?.slice(0, 3).map((_, index: number) => (
                <Box
                  key={index}
                  width={index === currentIndex ? "12px" : "8px"}
                  height={index === currentIndex ? "12px" : "8px"}
                  bgColor={index === currentIndex ? "purple.600" : "gray.300"}
                  borderRadius="9999px"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => handleMouseOn(data[index], index)}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>

        <Flex justifyContent="space-between" direction={{ base: "column", lg: "row" }} gap={{ base: "20px", lg: "0" }}>
          {data?.slice(0, 3).map((item: MainBanner, index: number) => (
            <Box onMouseEnter={() => handleMouseOn(item, index)} key={index}>
              <PostCardNoImg isOnBanner item={item} />
            </Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
}

export default MainBanner;
