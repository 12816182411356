import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  useToast,
} from "@chakra-ui/react";
import { subscribeUser } from "@strapi-newsletter/react";
import { Spinner } from "@chakra-ui/react";

import { configFile } from "@/config";
import Image from "next/image";

function SubscribeBlock() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handeSubscribeSubmit = async (e: any) => {
    e.preventDefault();

    if (!(e.target.email.value === "")) {
      setIsLoading(true);
      try {
        await subscribeUser(
          e.target.email.value,
          `${process.env.NEXT_PUBLIC_API_URL}`
        );
        toast({
          title: "Thank you.",
          description: "We've received your email address.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
        e.target.reset();
      } catch (error) {
        setIsLoading(false);
        toast({
          title: "Error!",
          description: "Probably you have already subscribed.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      setIsError(e.target.email.value === "");
    }
  };

  return (
    <Box
      as="section"
      width="100%"
      paddingTop={{ base: "60px", lg: "80px" }}
      bgColor="gray.50"
    >
      <Flex
        maxWidth="1216px"
        margin="0 auto"
        width={{ base: "100%", md: "90%" }}
        bgColor="purple.600"
        justifyContent="flex-end"
        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
        borderRadius="16px"
        padding={{ base: "64px 32px 32px 32px", md: "80px 112px" }}
        position="relative"
      >
        <Flex
          position="absolute"
          bottom="0"
          left="140px"
          display={{ base: "none", md: "flex" }}
        >
          <Image
            src="/images/subscribe-block/dog.png"
            alt="dog"
            width={289}
            height={438}
          />
        </Flex>
        <Flex
          position="absolute"
          right={{ base: "50%", md: "316px" }}
          left={{ base: "50%", md: "none" }}
          transform={{ base: "translateX(-50%)", md: "none" }}
          top="-52px"
          padding="16px 24px"
          width={{ base: "314px" }}
          justifyContent="center"
          alignItems="center"
          borderRadius="16px"
          bgColor="purple.400"
          boxShadow="0px 25px 50px -12px rgba(0, 0, 0, 0.25);"
          gap="10px"
        >
          <Image
            src="/images/subscribe-block/icon1.svg"
            alt="icon"
            width={58}
            height={58}
          />
          <Text
            fontSize="14px"
            lineHeight="20px"
            fontWeight="600"
            color="#FFF"
            textTransform="uppercase"
            width="200px"
          >
            Receba em primeira mão todas as novidades
          </Text>
        </Flex>
        <Flex
          flexDir="column"
          width="518px"
        >
          <Text
            fontSize="14px"
            lineHeight="20px"
            fontWeight="700"
            color="#FFF"
            mb="16px"
            textTransform="uppercase"
          >
            JUNTE-SE COM 6.000 PAIS DE PET
          </Text>
          <Heading
            as="h2"
            fontSize="36px"
            lineHeight="43px"
            fontWeight="700"
            color="yellow.200"
            mb="16px"
          >
            Assine a nossa newsletter!
          </Heading>
          <Text
            fontSize="16px"
            lineHeight="24px"
            fontWeight="400"
            color="#FFF"
            mb="24px"
          >
            Receba as novidades do nosso site em primeira mão.
          </Text>
          <form
            style={{ maxWidth: "518px", textAlign: "left" }}
            onSubmit={(e) => handeSubscribeSubmit(e)}
          >
            <Flex direction={{ base: "column", md: "row" }}>
              <FormControl isInvalid={isError}>
                <Input
                  variant="Outline"
                  size="lg"
                  height="56px"
                  display="flex"
                  flex="1 0 0"
                  border="1px solid #E2E8F0"
                  borderRadius={{ base: "6px", md: "26px 0 0 26px" }}
                  padding="14px 16px"
                  name="email"
                  placeholder="Digite seu e-mail e inscreva-se"
                  fontSize="18px"
                  lineHeight="28px"
                  fontWeight="400"
                  type="email"
                />
                {isError && (
                  <FormErrorMessage mb="10px">E-mail é obrigatório.</FormErrorMessage>
                )}
              </FormControl>
              <Button
                aria-label="Subscribe"
                size="lg"
                width={{ base: "100%", md: "137px" }}
                mt={{ base: "16px", md: "0" }}
                height="56px"
                bgColor="yellow.300"
                borderRadius={{ base: "26px", md: "0 26px 26px 0" }}
                p="10px 24px"
                fontSize="18px"
                lineHeight="28px"
                fontWeight="600"
                color="gray.700"
                type="submit"
                _hover={{ bgColor: "yellow.400" }}
              >
                Inscreva-se
              </Button>
            </Flex>
            {isLoading && (
              <Spinner
                margin=" 0 auto"
                mt="15px"
                color={configFile.primaryAccentColor}
              />
            )}
          </form>
        </Flex>
      </Flex>
    </Box>
  );
}

export default SubscribeBlock;
