import { configFile } from "@/config";
import { BlogPostsMainData } from "@/Types/types";
import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  Tabs,
  Button,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import Image from "next/image";

import Post from "@/components/MainPage/Post";

interface Props {
  posts: BlogPostsMainData[];
}

export default function BrowseByCategory({ posts }: Props) {
  const [categories, setCategories] = useState<{ name: string; icon: string }[]>([]);
  const [currentCategory, setCurrentCategory] = useState(0);
  const [curArticles, setCurArticles] = useState<BlogPostsMainData[]>([]);
  const [selectedButton, setSelectedButton] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<Boolean>();

  useEffect(() => {
    setIsMobile(window.innerWidth <= 830 ? true : false);
  })

  useEffect(() => {
    const categoryMap = new Map<string, { name: string, icon: string }>();

    const updatedArticles = posts
      .map((article) => {
        const categories = article.attributes.category.data.map((category) => {
          const categoryTitle = category.attributes.title;
          const categoryIcon = category.attributes.icon.data.attributes.url;

          categoryMap.set(categoryTitle, {
            name: categoryTitle,
            icon: categoryIcon,
          });

          return {
            title: categoryTitle,
            icon: categoryIcon,
          };
        });

        return {
          ...article,
          categories: categories,
        };
      });

    const allCategories = [
      { name: "Últimos posts", icon: "" },
      ...Array.from(categoryMap.values())
    ];

    setCategories(allCategories);
    setCurArticles(updatedArticles);
  }, [posts]);

  useEffect(() => {
    if (currentCategory === 0) {
      setCurArticles(posts);
    } else {
      setCurArticles(
        posts.filter((articles: any) =>
          articles.attributes.category.data.some(
            (category: any) =>
              category.attributes.title === categories[currentCategory].name
          )
        )
      );
    }
  }, [currentCategory]);

  const handleCategory = (index: number) => {
    setCurrentCategory(index);
  };

  const scrollRight = () => {
    const container = document.getElementById("categories-container");
    if (container) {
      if (
        container.scrollLeft + container.offsetWidth >=
        container.scrollWidth
      ) {
        container.scrollLeft = 0;
      } else {
        container.scrollLeft += container.offsetWidth;
      }
    }
  };

  return (
    <Flex
      direction="column"
      maxWidth="1216px"
      margin="0 auto"
      width={{ base: "100%", lg: "100%" }}
      gap={{ base: "32px", lg: "72px" }}
      pt={{ base: "0px", lg: "250px" }}
      pb={{ base: "40px", lg: "72px" }}
    >
      <Box
        as="section"
        width="100%"
        paddingBottom={{ base: "60px", lg: "0px" }}
        position="relative"
      >
        <Flex
          direction="column"
          w={{ base: "90%", lg: "100%" }}
          m="0 auto 32px auto"
          alignItems={{ base: "center", lg: "flex-start" }}
        >
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            mb={{ base: "32px", lg: "32px" }}
          >
            <Heading
              as="h2"
              width="100%"
              textAlign="left"
              color="gray.800"
              fontSize={{ base: "24px", lg: "36px" }}
              lineHeight={{ base: "32px", lg: "40px" }}
              fontWeight="800"
            >
              Navegue por Categoria
            </Heading>
          </Flex>
          <Tabs
            w="100%"
            variant="soft-rounded"
            colorScheme="gray"
            display="flex"
            flexDirection="column"
            defaultIndex={currentCategory}
          >
            <Flex
              id="categories-container"
              width={{ base: "100%", lg: "100%" }}
              overflowX="scroll"
              overflowY="visible"
              m="32px 0"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <TabList
                gap="16px"
                role="tablist"
                aria-orientation="horizontal"
                aria-label="menu"
                id={`${currentCategory}`}
              >
                {categories.map((item: any, index: number) => (
                  <Tab
                    key={index}
                    id={`tab-${index}-button`}
                    role="tab"
                    aria-selected={index === currentCategory}
                    aria-controls={`tab-${index}`}
                    height="48px"
                    minWidth={{ base: "150px", lg: "auto" }}
                    bgColor="#FFF"
                    color="gray.600"
                    _hover={{
                      bgColor: "purple.50",
                    }}
                    borderRadius="9999px"
                    p="12px 32px"
                    whiteSpace="nowrap"
                    onClick={() => handleCategory(index)}
                    _selected={{
                      color: "white",
                      bg: `${configFile.primaryAccentColor}`,
                      boxShadow: "base",
                    }}
                    onClickCapture={() => setSelectedButton(index)}
                  >
                    {item.icon && (
                      <Image
                        src={item.icon}
                        alt={item.name}
                        width="26"
                        height="26"
                        style={{
                          filter: index !== selectedButton
                            ? 'brightness(0.4) grayscale(1)' : 'none',
                          marginRight: "8px"
                        }}
                      />
                    )}
                    {item.name}
                  </Tab>
                ))}
              </TabList>
            </Flex>
            <Flex
              height="50px"
              alignItems="center"
              justifyContent="flex-end"
              position="absolute"
              right="0"
              top={{ base: "90px", lg: "105px" }}
            >
              <Box
                height="50px"
                width={{ base: "100px", lg: "139px" }}
                background="linear-gradient(270deg, #FFFCF6 0%, rgba(247, 250, 252, 0.00) 100%)"
                style={{ pointerEvents: "none" }}
                position="absolute"
              />
              <Button
                aria-label="scroll left"
                size="md"
                borderRadius="9999px"
                width="40px"
                height="40px"
                bgColor="gray.50"
                boxShadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)"
                _hover={{ bgColor: "gray.100" }}
                onClick={scrollRight}
              >
                <ArrowForwardIcon color="blue.900" />
              </Button>
            </Flex>
            <TabPanels width="100%" >
              {categories.map((_, index: number) => (
                <TabPanel
                  key={index}
                  width="100%"
                  id={`tab-${currentCategory}`}
                  role="tabpanel"
                  tabIndex={currentCategory}
                  aria-labelledby={`tab-${currentCategory}-button`}
                  p="0 !important"
                >
                  <Flex
                    width="100%"
                    direction={{ base: "column", lg: "row" }}
                    gap="32px"
                  >
                    <Flex width={{ base: "100%", lg: "70%" }} direction="column" gap="32px">
                      {curArticles &&
                        curArticles
                          .slice(0, 2)
                          .map((item: BlogPostsMainData, index: number) => (
                            <Box key={item.id} width={{ base: "100%", lg: "100%" }}>
                              <Post
                                post={item}
                                isHorizontal={!isMobile}
                                hasImage
                                hasParagraph
                                hasCategory
                              />
                            </Box>
                          ))}
                    </Flex>
                    <Flex width={{ base: "100%", lg: "30%" }} height="100%" direction="column">
                      {curArticles &&
                        curArticles
                          .slice(2, 3)
                          .map((item: BlogPostsMainData, index: number) => (
                            <Box key={item.id} width={{ base: "100%", lg: "100%" }}>
                              <Post
                                post={item}
                                isHorizontal={false}
                                hasImage
                                hasParagraph
                                hasCategory
                              />
                            </Box>
                          ))}
                    </Flex>
                  </Flex>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Flex>
      </Box>
    </Flex>
  );
}
